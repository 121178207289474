@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Raleway:wght@400;500;600;700&display=swap');

html {
  font-size: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: unset;
}

body {
  background: #f0f3f7;
  color: #333;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
  /* Hide vertical scrollbar */
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

a {
  color: #09233c;
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
}
.content {
  max-width: 960px;
  margin: 0 auto;
}

ul,
ol {
  list-style-type: none;
}
footer {
  display: block;
  text-align: center;
  padding: 30px 0;
  margin-top: 60px;
  color: #777;
  border-top: 1px solid #eaeaea;
}
.not-found {
  text-align: center;
}
.not-found a {
  color: #4979ff;
  text-decoration: underline;
}

.filepond--root,
.filepond--root .filepond--drop-label a {
  text-decoration: none;
}

/* editor style */
div.rc-md-editor {
  border: 1px solid #b0bfc7;
  border-radius: 0.8rem;
  overflow: hidden;
}
div.rc-md-navigation {
  background-color: #f0f3f7;
  border: none;
  border-radius: 0.8rem 0.8rem 0 0;
}

div.editor-container .section-container,
div.editor-container .Editor {
  color: #09233c;
  font-family: 'Raleway', sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media (max-width: 1024px) {
  div.editor-container {
    flex-direction: column;
    background-color: #f0f3f7;
    gap: 4rem;
  }
  div.editor-container :last-child {
    background-color: #fff;
  }
}

/* loader aniamtion */

@keyframes bounce {
  0% {
    height: 10px;
  }
  50% {
    height: 80px;
  }
  100% {
    height: 10px;
  }
}
@keyframes bounceDelayed {
  0% {
    height: 80px;
  }
  50% {
    height: 10px;
  }
  100% {
    height: 80px;
  }
}
.loader_wrap {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 100px;
  height: 100px;
  position: relative;
}
.loader > .line_1 {
  width: 10px;
  height: 80px;
  border-radius: 5px;
  background: linear-gradient(45deg, purple, orange);
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
  animation: bounceDelayed 1s infinite ease-in-out;
}
.loader > .line_2 {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(45deg, purple, orange);
  position: absolute;
  left: 43px;
  top: 50%;
  transform: translateY(-50%);
  animation: bounce 1s infinite ease-in-out;
}
.loader > .line_3 {
  width: 10px;
  height: 80px;
  border-radius: 5px;
  background: linear-gradient(45deg, purple, orange);
  position: absolute;
  left: 63px;
  top: 50%;
  transform: translateY(-50%);
  animation: bounceDelayed 1s infinite ease-in-out;
}
